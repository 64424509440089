<template>
    <div>
      
      <div @click="expandDiv"  :class="[{ 'expanded': isExpanded },{'contracted': !isExpanded}]" :style="{ height: expandedHeight + 'px' }">
        <div class="center" >
        <nav class="navbar expander-nav-container" role="navigation" aria-label="main navigation" style="background-color: hsl(0deg 0% 100% / 0%);">
            <div id="navbarBasicExample" class="navbar-menu">
              <div class="navbar-start">
                <div class="navbar-item has-text-white">
                    {{  title }}
                </div>
              </div>

              <div class="navbar-end">
                <div class="navbar-item">
                   
                    <span class="icon is-small has-text-white">
                      <i v-if="!isExpanded" class="fa-solid fa-circle-chevron-right"></i>
                      <i v-else class="fa-solid fa-circle-chevron-down"></i>
                    </span>
                
                </div>
              </div>
            </div>
          </nav>
        </div>
          <content>
            <slot name="content"></slot>
          </content>
          
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      title: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        isExpanded: false,
        expandedHeight: 80
      };
    },
    methods: {
      expandDiv() {
        this.isExpanded = !this.isExpanded;
        this.expandedHeight = this.isExpanded ? 285 : 80;
        this.$emit('hasExpanded', this.isExpanded);
      }
    }
  };
  </script>
  
  <style>
  .expanded {
    transition: height 0.3s ease-in-out;
    border-radius: 20px;
    background: rgba(18, 19, 156, 0.7);
  }

  .contracted{
    transition: height 0.3s ease-in-out;
    border-radius: 20px;
    background: rgba(18, 19, 156, 0.7);
  }
  </style>
  